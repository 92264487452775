import Layout from "../components/layout"
import SEO from "../components/seo"
import * as React from "react"
import { graphql } from "gatsby"
import TourCard, { ITourCardFragment } from "../components/tour-card"
import { ColumnIsOneFifth, Columns, ContainerOverview } from "../components/styled-components"

interface Props {
  data: {
    region: {
      name: string;
    };
    trips: {
      edges: {
        node: ITourCardFragment
      }[]
    };
  }
}

export default ({ data }: Props) => {
  return (
    <Layout>
      <SEO title={data.region.name}/>
      <ContainerOverview>
        <h2>{data.region.name}</h2>
        <Columns>
          {data.trips.edges.map(edge => (
            <ColumnIsOneFifth key={edge.node.title}>
              <TourCard tour={edge.node}/>
            </ColumnIsOneFifth>
          ))}
        </Columns>
      </ContainerOverview>
    </Layout>
  )
}


export const query = graphql`
  query($regionSlug: String!, $name: String!) {
    region:contentfulRegion(fields: { regionSlug: { eq: $regionSlug } }) {
      name
    }
    trips:allContentfulTrip(
        limit: 25, 
        sort:{fields: internal___contentDigest}, 
        filter: {regions: {elemMatch: {name:{eq: $name}}}}
      ) {
      edges { node { ...TourCardFragment } }   
    }
  }
`
